<template>
  <div class="content block-el">
    <div v-if="loading" class="static qt-block-ui" style="padding: 120px" />
    <template v-else>
      <PageHeader :title="`${plan.service} - ${plan.name}`" />
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-md-6">
            <card :title="$tc('billing.plan.your_plan')">
              <template #body>
                <span
                  v-html="$t('billing.plan.about', { name: plan.name })"
                ></span>
                <h4 class="m-t-20">{{ $tc('app.price_estimate') }}</h4>
                <span class="h3 text-danger">{{
                  plan.pivot.pricing.fee | currency
                }}</span>
              </template>
            </card>
          </div>
          <div class="col-md-6">
            <card :title="$tc('app.status')">
              <template #body>
                <div
                  v-for="(value, feature) in plan.pivot.features"
                  :key="feature"
                  class="row m-t-10"
                >
                  <div class="col-12">
                    {{ $tc(`app.${feature}`, 2) }}
                    <progress-bar
                      :current="plan.pivot.usage[feature]"
                      :max="value"
                    />
                  </div>
                </div>
              </template>
            </card>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <card :title="$tc('billing.plan.info')">
              <template #body>
                <span
                  v-for="(price, feature) in plan.pivot.pricing"
                  :key="feature"
                >
                  <h3 class="m-t-20">{{ $tc(`app.${feature}`) }}</h3>
                  <span class="h3 text-danger">{{
                    price | currency
                  }}</span>
                </span>
              </template>
            </card>
          </div>
          <div class="col-md-6">
            <logs-card />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import Card from '@/components/Card.vue';
import LogsCard from '@/components/cards/LogsCard.vue';
import ProgressBar from '@/components/ProgressBar.vue';

export default {
  name: 'BillingPlan',
  components: {
    PageHeader,
    Card,
    LogsCard,
    ProgressBar,
  },
  data() {
    return {
      loading: true,
      plan: {},
      logs: [],
    };
  },
  computed: {},
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      const planId = this.$route.params.planId;
      this.loading = true;
      this.$store.dispatch('fetchPlan', planId).then((response) => {
        this.plan = response;
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
